import React from 'react'
import styles from './NotFoundPage.module.scss'
import { useHistory } from 'react-router'


function NotFoundPage() {
    const history = useHistory()

    history.push("/")
    return (
        <div className={styles.wrapper}>
        Page Not Found
        </div>
    )
}

export default NotFoundPage