import React from 'react'
import styles from "./Price.module.scss"
import ethIcon from "../../images/logo/eth_logo.png"
import { useAppContext } from '../../contexts/AppContextProvider'

function Price({eth = 0, showExchange = true} : {eth ?: number, showExchange ?: boolean}) {
    const appContext = useAppContext()
    const currentPrice =appContext?.ethPrice ?? 0;
    return (
        <div> {eth > 0 && 
        <div>
            <div className={styles.pricingWrapper}>
                <img className={styles.ethIcon} src={ethIcon}/>
                <div className={styles.ethWrapper}>
                    <div className={styles.ethPrice}>
                        {eth}
                    </div>
                    <div className={styles.ethUnit}>
                        ETH
                    </div>
                </div>
                { (showExchange && currentPrice != 0 )  && <div className={styles.hkPriceWrapper}>
                    <div className={styles.vline}>
                        |
                    </div>
                    <div className={styles.hkPrice}>
                        HKD {Math.round(eth * currentPrice) }
                    </div>
                </div>}
            </div>
        </div> }
        </div>
    )
}

export default Price
