import React, { useContext, useEffect, useState } from 'react'

export type AppContent = {
    walletType: string | undefined,
    accounts: string[] | undefined,

    ethPrice: number | undefined,
    bidPrice: number | undefined,
    expiration: number | undefined,

    draw1Enable: string | undefined,
    draw3Enable: string | undefined,
    bidEnable: string | undefined
}

export type AppUpdateContent = {
    setAppUpdateContent: (walletType: string | undefined, accounts: string[] | undefined) => void
    setAccounts: (accounts: string[] | undefined) => void

    setBidPrice: (bidprice: number | undefined) => void
    setEthPrice: (ethPrice: number | undefined) => void
    setBidExpiration: (expiration: number | undefined) => void

    setDraw1Enable: (draw1Enable: string | undefined) => void
    setDraw3Enable: (draw3Enable: string | undefined) => void
    setBidEnable: (bidEnable: string | undefined) => void
}

const AppContext = React.createContext<AppContent | undefined>(undefined)
const AppUpdateContext = React.createContext<AppUpdateContent | undefined>(undefined)

export function useAppContext() {
    return useContext(AppContext)
}

export function useAppUpdateContext() {
    return useContext(AppUpdateContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function AppContextProvider(props: Props) {

    const [appContent, setAppContent] = useState<AppContent>({
        walletType: undefined,
        accounts: undefined,

        ethPrice: undefined,
        bidPrice: undefined,
        expiration: undefined,

        draw1Enable: undefined,
        draw3Enable: undefined,
        bidEnable: undefined
    })

    const setAppUpdateContent = (walletType: string | undefined, newAccounts: string[] | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'walletType': walletType, 'accounts': newAccounts}) );
        sessionStorage.setItem("walletType", walletType? walletType : "");
        sessionStorage.setItem("accounts", newAccounts? newAccounts[0] : "");
    }
    const setAccounts = (newAccounts: string[] | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'accounts': newAccounts}) );
        sessionStorage.setItem("accounts", newAccounts? newAccounts[0] : "");
    }

    const setEthPrice = (ethPrice: number | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'ethPrice': ethPrice})  );
    }
    const setBidPrice = (bidPrice: number | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'bidPrice': bidPrice})  );
    }
    const setBidExpiration= (expiration: number | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'expiration': expiration})  );
    }

    const setDraw1Enable = (draw1Enable: string | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'draw1Enable': draw1Enable? draw1Enable : ""})  );
    }
    const setDraw3Enable = (draw3Enable: string | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'draw3Enable': draw3Enable? draw3Enable : ""})  );
    }
    const setBidEnable= (bidEnable: string | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'bidEnable': bidEnable? bidEnable : ""})  );
    }

    const appUpdateContent: AppUpdateContent = {
        setAppUpdateContent: (walletType: string | undefined, accounts: string[] | undefined) => { setAppUpdateContent(walletType, accounts) },
        setAccounts: (accounts: string[] | undefined) => { setAccounts(accounts) },

        setEthPrice: (ethPrice: number | undefined) => { setEthPrice(ethPrice) },
        setBidPrice: (bidPrice: number | undefined) => { setBidPrice(bidPrice) },
        setBidExpiration: (expiration: number | undefined) => { setBidExpiration(expiration) },
        
        setDraw1Enable: (draw1Enable: string | undefined) => { setDraw1Enable(draw1Enable) },
        setDraw3Enable: (draw3Enable: string | undefined) => { setDraw3Enable(draw3Enable) },
        setBidEnable: (bidEnable: string | undefined) => { setBidEnable(bidEnable) }
    }

    return (
        <AppContext.Provider value={appContent}>
            <AppUpdateContext.Provider value={appUpdateContent}>
                {props.children}
            </AppUpdateContext.Provider>
        </AppContext.Provider>
    )
}

export default AppContextProvider
