import React from 'react'
import { Trans } from 'react-i18next'
import i18n from '../../i18n'
import styles from "./BidItemImage.module.scss"

function BidItemImage() {
    return (
        <div className={styles.bgWrapper}>
            <div className={`${styles.titleWrapper} ${i18n.language=='zh-HK'? styles.titleFontZhHK : styles.descriptionFontEn}`}>
                <div className={styles.clubTitle}><Trans i18nKey='bidItemSection.clubTitle'></Trans></div>
                <div className={styles.itemTitle}><Trans i18nKey='bidItemSection.itemTitle'></Trans></div>
            </div>
        </div>
    )
}

export default BidItemImage