import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './ProgressBar.module.scss'

function ProgressBar({step} : {step : number}) {
    const [t, i18n] = useTranslation()
    return (
            <div className={styles.progressBar}>
                <div className={styles.slotWrapper}>
                    {step >= 0 ?
                    <div className={styles.slotOn}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>1</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step1'></Trans>
                        </div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>1</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step1'></Trans>
                        </div>
                    </div>}

                    {step >= 1 ?
                    <div className={styles.progressLineOn}>
                    </div> :
                    <div className={styles.progressLineOff}>
                    </div>
                    }

                    {step >= 1 ?
                    <div className={styles.slotOn}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>2</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step2'></Trans>
                        </div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>2</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step2'></Trans>
                        </div>
                    </div>}

                    {step >= 2 ?
                    <div className={styles.progressLineOn}>
                    </div> :
                    <div className={styles.progressLineOff}>
                    </div>
                    }

                    {step >= 2 ?
                    <div className={styles.slotOn}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>3</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step3'></Trans>
                        </div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>3</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step3'></Trans>
                        </div>
                    </div>}

                    {step >= 3 ?
                    <div className={styles.progressLineOn}>
                    </div> :
                    <div className={styles.progressLineOff}>
                    </div>
                    }

                    {step >= 3 ?
                    <div className={styles.slotOn}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>4</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step4'></Trans>
                        </div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={`${styles.step} ${styles.descriptionFontEn}`}>4</div>
                        <div className={`${styles.slotTitle} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey='drawItemPopup.steps.step4'></Trans>
                        </div>
                    </div>}
            </div>
            </div>
    )
}

export default ProgressBar
