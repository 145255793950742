import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppContext } from '../../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../../contexts/OpenSeaContextProvider'
import { usePopupContext, usePopupUpdateContext } from '../../../contexts/PopupContextProvider'
import styles from './Draw3ItemsPopup.module.scss'
import ProgressBar from '../../../common/progressBar/ProgressBar'
import { useHistory } from 'react-router'
import Loading from '../../../common/loading/Loading'
import DotLoading from '../../../common/dotLoading/DotLoading'
import { OpenSeaAsset, OpenSeaAssetBundle, OpenSeaAssetBundleQuery } from 'opensea-js/lib/types'
import collectionBoxImg from '../images/collection_box.png'
import openBoxMp4 from "../../../images/RCFC_2021_STD_P_S1_OPEN.mp4"
import { useDraw3ItemsPopupContext } from '../../../contexts/popups/Draw3ItemsPopupContextProvider'
import GenericButton from '../../../common/genericButton/GenericButton'
import Price from '../../../common/price/Price'
import HowTo from '../../../common/howTo/HowTo'
import Terms from '../../../common/termsAndConditions/termsAndConditions'
import {singlePrice, packPrice} from '../../../AppSettings.json'
import {env, assetAddressMainnet, assetAddressRinkeby, awsXApiKey , assetOwnerMainnet , assetOwnerRinkeby} from '../../../secrets.json'
import { delay } from 'opensea-js/lib/utils/utils'
import portisIcon from '../images/portisSmall.png'
import metamaskIcon from '../images/metaMaskSmall.png'
import arrow from '../images/arrow.png'


function Draw3ItemsPopup() {
    enum Progress {
        Idle,
        Transaction,
        TransactionDone,
        TransactionFailed,
        LuckyDraw,
        LuckyDrawDone,
        LuckyDrawFailed
    }

    const { t, i18n } = useTranslation()
    const openSeaContext = useOpenSeaContext()
    const appContext = useAppContext()
    const draw3ItemsPopupContext = useDraw3ItemsPopupContext()
    const [progress, setProgress] = useState<Progress>(Progress.Idle)
    const [targetBundle, setTargetBundle] = useState<OpenSeaAssetBundle | undefined | null>(undefined)
    const [finalItems, setFinalItems] = useState<any[] | undefined>(undefined)
    const [errorMsg, setErrorMsg] = useState<any>("")
    const [termsChecked, setTermsChecked] = useState<boolean>(false)
    const history = useHistory()
    const isEnvMainnet = (env == "mainnet")

    // useEffect(() => { setProgress(Progress.TransactionDone) })

    const getShortAddress = (address : string| null) => {
        if (address == null ) return ""
        return address.substring(0, 6) + "..." + address.substring(address.length - 4)
    }

    const getFinalItemsDisplay = () => {
        if (finalItems) {
            return (<div className={styles.display}>
                {finalItems.map(finalItem => {
                    if (finalItem.animation_url) {
                        return <div>
                            <video className={styles.finalAnimation} src={finalItem.animation_url} autoPlay={true} loop={true}/>
                            <div>{finalItem.name}</div>
                        </div>
                    } else {
                        return <img className={styles.finalImage} src={finalItem.image}/>
                    }
                })}</div>)
        } else {
            return <div className={styles.loadingWrapper}><Loading width={128} height={128}/></div>
        }
    }

    const drawThree = async () => {
        try {
            setProgress(Progress.Transaction)
            var tokenAddress = isEnvMainnet? assetAddressMainnet : assetAddressRinkeby;
            var bundleMaker = isEnvMainnet? assetOwnerMainnet : assetOwnerRinkeby;
            var openSeaBundlesWithSellOrders : OpenSeaAssetBundle[] = []
            var query : OpenSeaAssetBundleQuery = {
                asset_contract_address: tokenAddress,
            }
            var page = 1
            var finish = false
            while (!finish){
                try{
                    await delay(1000); // avoid opensea api error429
                    var openSeaResult = await openSeaContext?.api.getBundles(query, page)
                    openSeaResult?.bundles.forEach((bundle) => {
                        // console.log( (bundle.sellOrders != null && bundle.sellOrders.length > 0) + " " + bundleMaker + " " + bundle.maker.address);
                        if (bundle.sellOrders != null && bundle.sellOrders.length > 0) {
                            if ( bundle.maker.address == bundleMaker){
                                openSeaBundlesWithSellOrders.push(bundle)
                            }
                        }
                    })
                    // console.log("page " + page + " bundles ", openSeaResult?.bundles);
                    page++;
                    if (openSeaResult != null && openSeaResult.bundles.length == 0) { finish = true }
                }
                catch(e){
                    setErrorMsg(t<string>('Error.draw3.drawOpenseaApi')) // ER03140
                    setProgress(Progress.TransactionFailed)
                    return
                }
            }
            
            var target : OpenSeaAssetBundle | undefined = undefined
            if (openSeaBundlesWithSellOrders.length == 0) {
                setErrorMsg(t<string>('Error.draw3.drawOpenseaSoldOut')) // ER03150
                setProgress(Progress.TransactionFailed)
                return
            }

            var itemsInBundle = undefined
            try {
                var drawUrl =  isEnvMainnet? 'https://api.hkfootballnft.com/v1/draw3' : 'https://api.hkfootballnft.com/draw3'
                var draw3Result = await axios.get(drawUrl)
                if (draw3Result.status == 200 && ( draw3Result.data.Items == null || draw3Result.data.Items.length > 0) )
                {
                    // Update and Retry as opensea have bundle but not draw3data table do not
                    var updateUrl =  isEnvMainnet? "https://api.hkfootballnft.com/v1/draw3/updatedata" : "https://api.hkfootballnft.com/draw3/updatedata"
                    await axios.get(updateUrl)
                    draw3Result = await axios.get(drawUrl)
                }
            }
            catch(e) {
                console.log("Error " + JSON.stringify(e))
                setErrorMsg(t<string>('Error.draw3.drawDrawApi')) // ER03130
                setProgress(Progress.TransactionFailed)
                return
            }

            if (draw3Result.status == 200 && draw3Result.data.Items && draw3Result.data.Items.length > 0) {
                itemsInBundle = draw3Result.data.Items
                // console.log(" -- server bundleItem -- ",itemsInBundle);
            } 
            else {
                setErrorMsg(t<string>('Error.draw3.drawDrawdataSoldOut')) // ER03110
                setProgress(Progress.TransactionFailed)
                return
            }
            
            if (itemsInBundle) {
                var tokenId = itemsInBundle[0].token_id
                for (const openSeaBundle of openSeaBundlesWithSellOrders) {
                    var assets = openSeaBundle.assets;  
                    if (assets.filter( item => item.tokenId == tokenId).length > 0 ) {
                        target = openSeaBundle
                        break
                    }
                }
            }
 
            if (!target) {
                // Update meta data and draw data as opensea not match meta data
                try {
                    for ( const wrongItem of itemsInBundle) {
                        if (isEnvMainnet) {
                            var result = await axios.put("https://api.hkfootballnft.com/updateowner", { "tokenId" : wrongItem.tokenId } , {
                                headers : { 'X-Api-Key' : awsXApiKey }
                            })
                            console.log("update meta data result", result)
                        }
                        else {
                            var result = await axios.put("https://api.hkfootballnft.com/metadata", { "tokenId" : wrongItem.tokenId })
                            console.log("update meta data result", result)
                        }
                    }
                }
                catch(e) {
                    setErrorMsg(t<string>('Error.draw3.drawUpdateonwerApi'))// ER03120
                    setProgress(Progress.TransactionFailed)
                }
                var url =  isEnvMainnet? "https://api.hkfootballnft.com/v1/draw3/updatedata" : "https://api.hkfootballnft.com/draw3/updatedata"
                await axios.get(url)
                if (itemsInBundle) {
                    setErrorMsg(t<string>('Error.draw3.drawOpenseaSoldOut')) // ER03150
                    setProgress(Progress.TransactionFailed)
                }
                else{
                    setErrorMsg(t<string>('Error.draw3.drawDrawdataSoldOut')) // ER03110
                    setProgress(Progress.TransactionFailed)
                }
                return
            }
            else{
                setTargetBundle(target)
            }

        } catch (e) {
            setErrorMsg(t<string>('Error.draw3.drawRunTime'))// ER03160
            setProgress(Progress.TransactionFailed)
            console.log('e ' + JSON.stringify(e))
        }
    }

    useEffect(() => {
        async function onTargetBundleAvailable() {
            if (targetBundle) {
                console.log("On target bundle Available")

                try {
                    if (!(appContext?.accounts && appContext?.accounts.length > 0)) {
                        setErrorMsg(t<string>('Error.draw3.orderWallet')) // ER03210
                        setProgress(Progress.TransactionFailed)
                        return
                    }
                    if (!openSeaContext) {
                        setErrorMsg(t<string>('Error.draw3.orderOpenseaContext')) // ER03220
                        setProgress(Progress.TransactionFailed)
                        return
                    }
                    if (!(targetBundle.sellOrders && targetBundle.sellOrders.length > 0)) {
                        setErrorMsg(t<string>('Error.draw3.orderOpenseaSellData') + " ref: " + targetBundle.slug) // ER03240
                        setProgress(Progress.TransactionFailed)
                        luckyDraw()
                        return
                    }

                    console.log("target slug " + JSON.stringify(targetBundle.slug))
                    // console.log(JSON.stringify(targetBundle.assets))

                    var sellOrder = targetBundle.sellOrders[0]
                    var isOrderFulfillable = await openSeaContext.isOrderFulfillable({
                        order: sellOrder,
                        accountAddress: appContext.accounts[0],
                        recipientAddress: appContext.accounts[0]
                    })

                    if (isOrderFulfillable) {
                        var orderResult = await openSeaContext?.fulfillOrder({
                            order: sellOrder,
                            accountAddress: appContext.accounts[0]
                        })
                        // console.log("Order Result " + JSON.stringify(orderResult))

                        setProgress(Progress.TransactionDone)
                        luckyDraw()

                    } else {
                        setErrorMsg(t<string>('Error.draw3.orderCannotFulfill') + " ref: " + targetBundle.slug) // ER03250
                        setProgress(Progress.TransactionFailed)
                    }

                } catch (e) {
                    console.log("Error " + JSON.stringify(e))
                    setErrorMsg(t<string>('Error.draw3.orderOpenseaApi') + " ref: " + targetBundle.slug) // ER03260
                    setProgress(Progress.TransactionFailed)
                }
            }
        }
        onTargetBundleAvailable()
        return () => {

        }
    }, [targetBundle])

    const luckyDraw = async () => {
        console.log('luckyDraw')
        setProgress(Progress.LuckyDraw)
        setTimeout(() => {
            setProgress(Progress.LuckyDrawDone)
        }, 3000)

        try {
            if (targetBundle?.assets) {
                var tmpFinalItems: OpenSeaAsset[] | undefined = []
                for(const asset of targetBundle.assets) {
                    var result = undefined
                    try {
                        if (isEnvMainnet) {
                            result = await axios.put("https://api.hkfootballnft.com/updateowner", { "tokenId" : asset.tokenId } , {
                                headers : { 'X-Api-Key' : awsXApiKey }
                            })
                        }
                        else {
                            result = await axios.put("https://api.hkfootballnft.com/metadata", { "tokenId" : asset.tokenId })
                        }
                    }
                    catch(e){
                        setErrorMsg(t<string>("Error.draw3.updateUpdateownerApi") + asset.tokenId) // ER03320
                        setProgress(Progress.LuckyDrawFailed)
                    }

                    if (result?.status == 200) {
                        try {
                            var url =  isEnvMainnet? "https://api.hkfootballnft.com/v1/draw3/updatedata" : "https://api.hkfootballnft.com/draw3/updatedata"
                            await axios.get(url)
                        } catch(e) {
                            console.log("Refresh Sales Metadata runtime error")
                        }
                        
                        try {
                            var url = isEnvMainnet ? "https://api.opensea.io/api/v1/asset/" : "https://testnets-api.opensea.io/api/v1/asset/"
                            await axios.get(url + asset.tokenAddress + "/" + asset.tokenId + "?force_update=true")
                        } catch(e) {
                            console.log("Refresh OpenSea Metadata runtime error")
                        }

                        try {
                            var jsonUrl = isEnvMainnet?  "https://api.hkfootballnft.com/data/": "https://api.hkfootballnft.com/json/"
                            var metaDataResult = await axios.get(jsonUrl + asset.tokenId)
                            if (metaDataResult.status == 200) {
                                tmpFinalItems?.push(metaDataResult.data)
                            } else {
                                setErrorMsg(t<string>("Error.draw3.updateJsonApi") + " ref: " + asset.tokenId)
                                setProgress(Progress.LuckyDrawFailed)
                            }
                        } catch(e) {
                            setErrorMsg(t<string>("Error.draw3.updateJsonApi"))
                            setProgress(Progress.LuckyDrawFailed)
                        }
                    } else {
                        setErrorMsg(t<string>('Error.draw3.updateUpdateownerApi'))
                        setProgress(Progress.LuckyDrawFailed)
                    }
                }
                // console.log('tmpFinalItems ' + JSON.stringify(tmpFinalItems, null, 2))
                setFinalItems(tmpFinalItems)
            } 
        } catch(e) {
            setErrorMsg(t<string>('Error.draw3.updateRunTime'))
            setProgress(Progress.LuckyDrawFailed)
        }
    }

    const onClose = () => {
        window.location.reload();
        draw3ItemsPopupContext?.show(false)
    }

    const gotoHome = () => {
        draw3ItemsPopupContext?.show(false)
        history.push("/")
    }

    const gotoCollection = () => {
        draw3ItemsPopupContext?.show(false)
        history.push("/collection")
    }

    const tryAgain = () => {
        window.location.reload();
        console.log('tryAgain')
        draw3ItemsPopupContext?.show(false)
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.container}>
            <div className={styles.closeButtonWrapper}>
                <div className={`${styles.closeButton} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`} onClick={() => {onClose()}}><Trans i18nKey='wrapEthPopup.closeButton'></Trans></div>
            </div>
            <div id="drawItemPopup" className={styles.popupWrapper}>
                {progress == Progress.Transaction && <div className={styles.progressBarWrapper}><ProgressBar step={0}/></div>}
                {progress == Progress.TransactionDone && <div className={styles.progressBarWrapper}><ProgressBar step={1}/></div>}
                {progress == Progress.LuckyDraw && <div className={styles.progressBarWrapper}><ProgressBar step={2}/></div>}
                {progress == Progress.LuckyDrawDone && <div className={styles.progressBarWrapper}><ProgressBar step={3}/></div>}
                <div className={styles.contentWrapper}>
                    {progress == Progress.Idle && <div className={styles.idleWrapepr}>
                    <div className={styles.descriptionWrapper}>
                        <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                            <Trans i18nKey="draw3ItemsPopup.title"></Trans>
                        </div>
                        <div className={styles.itemImage}><img src={collectionBoxImg}/></div>
                        <div className={`${styles.name} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}><Trans i18nKey='draw3ItemsPopup.name'></Trans></div>
                        <div className={styles.line}></div>
                        <div className={`${styles.price} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}><Trans i18nKey='draw3ItemsPopup.total'></Trans><Price eth={packPrice}/></div>
                        <div className={`${styles.terms} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}><input className={styles.checkbox} type="checkbox" onClick={(value: any) =>{ setTermsChecked(!termsChecked) }}/>
                            <Terms text={t<string>('drawItemPopup.terms')} /></div>
                        <div className={styles.ctaSection}>
                            <GenericButton value={t<string>('draw3ItemsPopup.ctaButton.value')} onClick={()=>{ drawThree() }} enabled={termsChecked} />
                        </div>
                        <div className={styles.howToPurchase}>
                            <HowTo text={t<string>('drawItemPopup.howToPurchase')} link={t<string>('mediumLinks.howToBuy')} ></HowTo>
                        </div>
                    </div>

                    </div>}
                    {progress == Progress.Transaction && <div className={styles.transactionWrapper}>
                        <div  className={styles.detailsWrapper}>
                        <div className={styles.imageWrapper}>
                            <div>
                                <img src={collectionBoxImg}/>
                            </div>
                            < div className={styles.horizontalLine} > 
                                <img className={styles.icon} src={arrow}/>
                            </div>
                            <div className={styles.userImage}>
                                { sessionStorage.getItem("wallet_type") == "MetaMask" && <img className={styles.icon} src={metamaskIcon} />}
                                { sessionStorage.getItem("wallet_type") == "Portis" && <img className={styles.icon} src={portisIcon} />}
                                { (sessionStorage.getItem("accounts") != "" && sessionStorage.getItem("accounts")!=null) && <div className={`${styles.address} ${styles.descriptionFontEn}`}> { getShortAddress(sessionStorage.getItem("accounts")) }</div>}
                            </div>
                        </div>
                        <div className={styles.instructionWrapper}>
                            <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.transaction.title"></Trans>
                            </div>
                            <div className={`${styles.description} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.transaction.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <DotLoading width={16} height={16} borderRadius={16}/>
                            </div>
                        </div>
                        </div>
                    </div>}
                    {progress == Progress.TransactionDone && <div className={styles.transactionWrapper}>
                        <div className={styles.imageWrapper}>
                            <div>
                                <img src={collectionBoxImg}/>
                            </div>
                            < div className={styles.horizontalLine} > 
                                <img className={styles.icon} src={arrow}/>
                            </div>
                            <div className={styles.userImage}>
                                { sessionStorage.getItem("wallet_type") == "MetaMask" && <img className={styles.icon} src={metamaskIcon} />}
                                { sessionStorage.getItem("wallet_type") == "Portis" && <img className={styles.icon} src={portisIcon} />}
                                { (sessionStorage.getItem("accounts") != "" && sessionStorage.getItem("accounts")!=null) && <div className={`${styles.address} ${styles.descriptionFontEn}`}> { getShortAddress(sessionStorage.getItem("accounts")) }</div>}
                            </div>
                        </div>
                        <div className={styles.instructionWrapper}>
                            <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.transactionCompleted.title"></Trans>
                            </div>
                            <div className={`${styles.description} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.transactionCompleted.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <DotLoading width={16} height={16} borderRadius={16}/>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.TransactionFailed && <div className={styles.transactionFailedWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.transactionFailed.title"></Trans>
                            </div>
                            <div className={`${styles.description} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.transactionFailed.description"></Trans>
                            </div>
                            <div className={`${styles.error} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <div className={styles.errorMsg}><Trans i18nKey="draw3ItemsPopup.transactionFailed.error"></Trans> : {errorMsg}</div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.gotoHomeWrapper}>
                                    <GenericButton hollow={true} value={t<string>('draw3ItemsPopup.transactionFailed.gotoHome')} onClick={()=>{gotoHome() }} />
                                </div>
                                <div className={styles.tryAgainWrapper}>
                                    <GenericButton value={t<string>('draw3ItemsPopup.transactionFailed.tryAgain')} onClick={()=>{tryAgain() }} />
                                </div>
                            </div>
                            <div className={styles.contactUs}>
                                <a href="mailto:contact@olivex.ai" className={`${styles.mailto} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}><Trans i18nKey='footer.contact_us'></Trans></a>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDraw && <div className={styles.luckyDrawWrapper}>
                        <video className={styles.openBox} src={openBoxMp4} autoPlay={true}/>
                        <div className={styles.instructionWrapper}>
                            <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDraw.title"></Trans>
                            </div>
                            <div className={`${styles.description} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDraw.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <div className={styles.loadingDot}></div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDrawDone && <div className={styles.luckyDrawDoneWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDrawDone.title"></Trans>
                            </div>
                            <div className={`${styles.description} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                            <Trans i18nKey="draw3ItemsPopup.luckyDrawDone.description.part1"></Trans>
                            {getFinalItemsDisplay()}
                            <div className={styles.message}><Trans i18nKey="draw3ItemsPopup.luckyDrawDone.description.part2"></Trans></div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.gotoHomePageWrapper}>
                                    <GenericButton hollow={true} value={t<string>('draw3ItemsPopup.luckyDrawDone.gotoHome')} onClick={()=>{gotoHome() }} />
                                </div>
                                <div className={styles.collectionWrapper}>
                                    <GenericButton value={t<string>('draw3ItemsPopup.luckyDrawDone.gotoCollection')} onClick={()=>{gotoCollection() }} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDrawFailed && <div className={styles.transactionFailedWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDrawFailed.title"></Trans>
                            </div>
                            <div className={`${styles.description} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDrawFailed.description"></Trans>
                            </div>
                            <div className={`${styles.error}  ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <div className={styles.errorMsg}><Trans i18nKey="draw3ItemsPopup.luckyDrawFailed.error"></Trans> : {errorMsg}</div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.tryAgainWrapper}>
                                    <GenericButton  value={t<string>('draw3ItemsPopup.luckyDrawFailed.tryAgain')} onClick={()=>{gotoHome() }} />
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            </div>        
        </div>
    )
}


export default Draw3ItemsPopup
