import { Trans, useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './BidItemSection.module.scss'
import alertCircle from '../images/alert-circle.png'
import GenericButton from '../../../common/genericButton/GenericButton'
import lcMp4 from '../../../images/RCFC_2021_BEST_1_LS.mp4'
import { isMobile } from 'react-device-detect'
import BidItemImage from '../../../common/bidItemImage/BidItemImage'
import HowTo from '../../../common/howTo/HowTo'
import DateCounter from '../../../common/dateCounter/DateCounter'
import {IsExpired} from '../../../common/dateCounter/DateCounter'
import Price from '../../../common/price/Price'
import {bid_start_month, bid_start_day, bid_start_hour, bid_start_minute } from '../../../AppSettings.json'
import {bid_end_month, bid_end_day, bid_end_hour, bid_end_minute, bidEnabled } from '../../../AppSettings.json'
import { useAppContext, useAppUpdateContext } from '../../../contexts/AppContextProvider'

function BidItemSection() {
    const statusOptions = {
        startSoon : "startSoon",
        started : "started",
        extended : "extended",
        ended : "ended",
        disabled : "disabled"
    } 
    const settingOptions = ["started" ,"extended" , "ended"]
    const appContext = useAppContext()
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const [bidStatus , setBidStatus] = useState<string>(statusOptions.startSoon)
    const [bidButtonText , setBidButtonText] = useState<string>(t<string>('bidItemSection.ctaButton.title'))
    const [ isStarted , setIsStarted ] = useState<boolean>(false)
    const [ isExpired , setIsExpired ] = useState<boolean>(false)

    const gotoBidItemDetailsPage = () => {
        history.push('/biditem')
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        const interval  = setInterval(() => { 
            let start = IsExpired( {year:2021,  month:bid_start_month-1, day:bid_start_day , hour:bid_start_hour,  minute:bid_start_minute , second:0} );
            let expired = IsExpired( {year:2021,  month:bid_end_month-1, day:bid_end_day , hour:bid_end_hour,  minute:bid_end_minute , second:0} );
            setIsStarted(start);
            setIsExpired(expired) 
        } , 1000 ) 
        return () => clearInterval(interval)
    } , [] )

    useEffect(() => {
        updateStatus()
    } , [appContext?.bidEnable , isExpired , isStarted , appContext?.expiration , i18n.language ] )

    function updateStatus() {
        let onSellOrder = appContext?.expiration != 0
        let serverSetting =  (appContext?.bidEnable != null && settingOptions.includes(appContext.bidEnable))?  appContext.bidEnable : "";

        if (!bidEnabled) {
            setBidStatus(statusOptions.disabled)
            setBidButtonText(t<string>('bidItemSection.ctaButton.' + statusOptions.ended))
            return;
        }

        if (serverSetting != "") {
            setBidStatus(serverSetting)
            setBidButtonText(t<string>('bidItemSection.ctaButton.' + serverSetting))
            return;
        } 
        if (!isStarted) { 
            setBidStatus(statusOptions.startSoon)
            setBidButtonText(t<string>('bidItemSection.ctaButton.' + statusOptions.startSoon))
            return
        }
        if (!isExpired) {
            setBidStatus(statusOptions.started)
            setBidButtonText(t<string>('bidItemSection.ctaButton.' + statusOptions.started))
            return
        }
        if (onSellOrder) {
            setBidStatus(statusOptions.extended)
            setBidButtonText(t<string>('bidItemSection.ctaButton.' + statusOptions.extended))
            return
        }
        setBidStatus(statusOptions.ended)
        setBidButtonText(t<string>('bidItemSection.ctaButton.' + statusOptions.ended))
    }

 return (
        <div className={styles.bgWrapper}>
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <div className={styles.logoParentWrapper}>
                    <BidItemImage/>
                </div>

                <div className={`${styles.description} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}><Trans i18nKey='bidItemSection.description'></Trans></div>
                <div className={styles.disabled}><Trans i18nKey='bidItemSection.disabled'></Trans></div>

                    {bidStatus == statusOptions.startSoon  && <div>
                        <DateCounter year={2021} month={bid_start_month-1} day={bid_start_day} hour={bid_start_hour} minute={bid_start_minute} second={0}/>
                    </div> }
                    {(bidStatus == statusOptions.started || bidStatus == statusOptions.extended )  && <div>
                        {(bidStatus == statusOptions.extended ) && <div className={`${styles.endSoon} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}><Trans i18nKey='bidItemSection.endSoon'></Trans></div> }
                        {(bidStatus == statusOptions.started) &&  <DateCounter year={2021} month={bid_end_month-1} day={bid_end_day} hour={bid_end_hour} minute={bid_end_minute} second={0}/>}
                    </div> }
                    {(!isMobile && bidStatus != statusOptions.disabled )&& <div className={styles.ctaButtonWrapper}>
                    <div className={styles.ctaButton}>
                        <GenericButton  enabled={(bidStatus == statusOptions.started || bidStatus == statusOptions.extended )} center={false} 
                                        value={bidButtonText} 
                                        onClick={()=>{ gotoBidItemDetailsPage() }}/>
                    </div>
                    {(bidStatus == statusOptions.started || bidStatus == statusOptions.extended )&& <Price eth={appContext?.bidPrice}/>}
                </div>}
                <div className={styles.howToWrapper}><HowTo text={t<string>('bidItemSection.howToBid')} link={t<string>('mediumLinks.howToBuy')} /></div>
            </div>
            <div className={styles.videoWrapper}>
                <video className={styles.videoContent} src={lcMp4} autoPlay={true} loop={true} muted width={480} height={480}/>
            </div>
        </div>
        </div>
    )
}

export default BidItemSection
