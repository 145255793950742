import { Fragment, useEffect, useState } from 'react'
import styles from './TitleBar.module.scss'
import rcfcLogo from '../images/logo/logo_standard_white1.png'
import i18next from 'i18next'
import { useAppContext, useAppUpdateContext } from '../contexts/AppContextProvider'
import { Link, useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { usePortisContext } from '../contexts/wallets/PortisContextProvider'
import { useWeb3Context } from '../contexts/Web3ContextProvider'
import WalletActionDropdown from './walletActionDropdown/WalletActionDropdown'
import GenericButton from '../common/genericButton/GenericButton'
import portisIcon from './images/portisSmall.png'
import metamaskIcon from './images/metaMaskSmall.png'
import { isMobile, MobileView } from 'react-device-detect'
import detectEthereumProvider from '@metamask/detect-provider';
import { useOpenSeaContext, useOpenSeaUpdateContext } from '../contexts/OpenSeaContextProvider'
import Portis from '@portis/web3';
import Web3 from 'web3';
import {env, portisAppid, openseaAppid, assetAddressMainnet , assetAddressRinkeby , auctionIdMainnet , auctionIdRinkeby} from '../secrets.json'
import { Network, OpenSeaPort } from 'opensea-js'

function TitleBar() {
    const appContext = useAppContext()
    const appUpdateContext = useAppUpdateContext()
    const { t, i18n } = useTranslation()
    const portisContext = usePortisContext()
    const web3Context = useWeb3Context()
    const history = useHistory()
    const [showWallet, setShowWallet] = useState(false)
    const PRICE_UPDATE_INTERVAL = 120000
    const [updateQueued, updateQueue] = useState(false)
    const openSeaUpdateContext = useOpenSeaUpdateContext()
    const openSeaContext = useOpenSeaContext()
    const isEnvMainnet = (env == "mainnet")

    useEffect(() => {
        var currentLanguage = localStorage.getItem('current_language')
        const interval = queuePriceCheck();
        updateIcon();
        listenModeChange();
        if (currentLanguage == "zh-HK") {
            i18next.changeLanguage("zh-HK")
        } else if (currentLanguage == "en") {
            i18next.changeLanguage("en")
        }
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        const checkConnection = async () => {

        if (sessionStorage.getItem("wallet_type") == 'MetaMask'){
            reconnectMetaMask();
        }

        if (sessionStorage.getItem("wallet_type") == 'Portis'){
            reconnectNewPortis();
        }

        };
        checkConnection();
    }, []);

    const reconnectMetaMask = async () => {
        try {
                const provider: any = await detectEthereumProvider({
                mustBeMetaMask: true
            })

            if (provider) {
                if (provider !== window.ethereum) {
                    console.error('Do you have multiple wallets installed?');
                    return
                }
                var accounts = await provider.request({ method: 'eth_requestAccounts'})
                appUpdateContext?.setAppUpdateContent("MetaMask", accounts)

                if (provider) {
                    var newOpenSeaPort : OpenSeaPort = new OpenSeaPort(provider, {
                        networkName: isEnvMainnet? Network.Main : Network.Rinkeby,
                    })
                    openSeaUpdateContext?.setOpenSeaContent(newOpenSeaPort)
                }
                sessionStorage.setItem("wallet_type", "MetaMask")
                const web3 = new Web3(provider)
                web3Context?.setWeb3(web3)
            } else {
                console.log("No Provider")
            }
        } catch (e) {
            console.log('Error ' + e)
        } finally {
        }
    }

    const reconnectNewPortis = async () => {
        try {
            var address = sessionStorage.getItem("accounts")
            if ((address != "" && address!=null)){
                console.log("Reconnecting ... ");
                const portis = new Portis(portisAppid, isEnvMainnet? 'mainnet' : 'rinkeby')
                const provider = portis.provider
                const web3 = new Web3(provider)

                appUpdateContext?.setAppUpdateContent("Portis", [address])
                portisContext?.setPortis(portis)
                web3Context?.setWeb3(web3)
                var newOpenSeaPort : OpenSeaPort = new OpenSeaPort(provider, {
                    networkName: isEnvMainnet? Network.Main : Network.Rinkeby,
                })
                openSeaUpdateContext?.setOpenSeaContent(newOpenSeaPort)
            }
            else{
                sessionStorage.setItem("wallet_type", "")
            } 
        } catch (e) {
            console.log("e " + JSON.stringify(e))
        } finally {
            console.log("finally")
        }
    }

    const listenModeChange =() =>{
        window.matchMedia('(prefers-color-scheme: dark)').addListener(() => { updateIcon () })
    }

    const updateIcon = () => {
        var match  = window.matchMedia('(prefers-color-scheme: dark)').matches
        var link = (document.querySelector("link[rel*='icon']") || document.createElement('link') )as any;
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        if (match) {
            link.href = '/favicon_white.png'
        } else {
            link.href = '/favicon_black.png'
        }
        document.getElementsByTagName('head')[0].appendChild(link)        
    }

    const queuePriceCheck = () => {
        getPrice();
        return window.setInterval(function(){getPrice();}, PRICE_UPDATE_INTERVAL )
    }

    const getPrice = async () => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET","https://api.coinbase.com/v2/prices/ETH-HKD/spot",true);
        xhr.responseType = 'json';
        xhr.onload = function() {
          var status = xhr.status;
          if (status === 200) {
            appUpdateContext?.setEthPrice(xhr.response.data.amount);
          } else {
            console.log("Cannot get Eth price -- ", xhr.response)
          }
        };
        xhr.send();
        getBidPriceAndDate();
        getStockStatus();
    }

    const   getStockStatus = async () => {
    var xhr = new XMLHttpRequest();
    var url =  isEnvMainnet? "https://api.hkfootballnft.com/v1/stockstatus" : "https://api.hkfootballnft.com/stockstatus"
    xhr.open("GET",url);
    xhr.responseType = 'json';
    xhr.onload = function() {
      var status = xhr.status;
      if (status === 200) {
        appUpdateContext?.setBidEnable(xhr.response.auctionEnable)
        appUpdateContext?.setDraw1Enable(xhr.response.draw1Stock)
        appUpdateContext?.setDraw3Enable(xhr.response.draw3Stock)
        // console.log("Stock status -- ", xhr.response)

      } else {
        console.log("Cannot get Stockstatus -- ", xhr.response)
      }
    };
    xhr.send();

}

    const getBidPriceAndDate = async () => {

        var tokenAddress = isEnvMainnet? assetAddressMainnet : assetAddressRinkeby;
        var tokenId = isEnvMainnet? auctionIdMainnet : auctionIdRinkeby;
        async function getAsset() {
            try {
                var asset = await openSeaContext?.api.getAsset({
                    "tokenAddress": tokenAddress,
                    "tokenId": tokenId
                })
                var prices = asset?.buyOrders?.map(buyOrder => buyOrder?.basePrice.shift(-18).toNumber())

                if (prices && prices.length != 0) { 
                prices = prices.sort((a,b) => b-a);
                prices[0] =  Math.round( prices[0] * 1000 ) / 1000
                appUpdateContext?.setBidPrice(prices[0])
                } 
                else if (asset?.sellOrders != null && asset.sellOrders.length > 0)
                {
                    var price =  asset.sellOrders[0].basePrice.shift(-18).toNumber();
                    appUpdateContext?.setBidPrice(price)
                } else {
                    // appUpdateContext?.setBidPrice(1);
                }

                var expirationTime = asset?.sellOrders?.map(order => order?.expirationTime.toNumber())
                if (expirationTime && expirationTime.length != 0) { 
                    expirationTime = expirationTime.sort((a,b) => b-a);
                    var d = new Date(0);
                    d.setDate(d.getDate() - 7);
                    d.setUTCSeconds(expirationTime[0]);
                    appUpdateContext?.setBidExpiration(expirationTime[0])
                }else{
                    appUpdateContext?.setBidExpiration(0)
                }
            }
            catch(e){
                
            }
        }
        getAsset();
    }

    const selectLanguage = (event: any) => {
        // console.log(JSON.stringify(event.target.value, null, 2))
        i18next.changeLanguage(event.target.value)
        localStorage.setItem('current_language', event.target.value)
    }

    const getLanguage = () => {
        var currentLanguage = localStorage.getItem('current_language')
        if (!currentLanguage) {
            return "zh-HK"
        }
        return currentLanguage
    }

    const getAccount = () => {
        if (appContext?.accounts) {
            var account = appContext.accounts[0]
            return account.substring(0, 6) + "..." + account.substring(account.length - 4)
        }
    }

    const logout = async () => {
        web3Context?.setWeb3(undefined)
        var walletType = sessionStorage.getItem("wallet_type")
        if (walletType == "MetaMask") {
            // reset();
        }
        else if (walletType == "Portis") {
            var portis = portisContext?.portis
            if (portis) {
                portisContext?.setPortis(undefined)
                /*
                    portis.showPortis()
                    portis.onLogout(()=>{
                        console.log("Logged Out")
                    })
                    if (portis.isLoggedIn()) {
                        portis.logout()
                    }
                    console.log("Logged Out3")
                */
            }
        }
        appUpdateContext?.setAppUpdateContent(undefined, undefined)
        sessionStorage.setItem('wallet_type', '')
        history.push('/wallet')
    }

    const switchWallet = async () => {
        history.push('/wallet')

        var walletType = sessionStorage.getItem("wallet_type")
        if (walletType == "MetaMask") {

        }
        else if (walletType == "Portis") {
            var portis = portisContext?.portis
            if (portis) {
                /*
                    portis.showPortis()
                    portis.onLogout(()=>{
                        console.log("Logged Out")
                    })
                    if (portis.isLoggedIn()) {
                        portis.logout()
                    }
                    console.log("Logged Out3")
                */
            }
        }
    }

    const toggleWalletDropdown = () => {
        setShowWallet(!showWallet)
    }

    const openWallet = async () => {
        var walletType = sessionStorage.getItem("wallet_type")
        if (walletType == "Portis") {
            var portis = portisContext?.portis
            if (portis) {
                portis.showPortis()
            }
        }
    }

    const getWalletSection = () => {
        return appContext?.accounts || ( sessionStorage.getItem("wallet_type") == "Portis" )  ?
            <Fragment>
                <div className={styles.userSection}>
                    <div className={styles.collectionWrapper}>
                        <div className={styles.imageWrapper}>
                            <input className={styles.collection} type="button" onClick={()=> {toggleWalletDropdown()}}/>
                            { sessionStorage.getItem("wallet_type") == "MetaMask" && <img className={styles.metaMaskIcon} src={metamaskIcon} onClick={()=> {toggleWalletDropdown()}}/>}
                            { sessionStorage.getItem("wallet_type") == "Portis" && <img className={styles.portisIcon} src={portisIcon} onClick={()=> {toggleWalletDropdown()}}/>}
                        </div>
                        {showWallet && <div className={styles.walletActionDropdownWrapper}><WalletActionDropdown logout={logout} switchWallet={switchWallet}/></div>}
                    </div>
                    <div className={styles.walletWrapper}>
                        <input className={`${styles.wallet} ${styles.descriptionFontEn}`} type="button" value={getAccount()?  getAccount() : t<string>('titlebar.connectingWallet')} onClick={() => { openWallet() }}/>
                    </div>
                </div>
            </Fragment> 
            :
            <div className={styles.ctaButtonWrapper}>
                <GenericButton value={t<string>('titlebar.connectWallet')} onClick={()=>{ history.push('./wallet') }} />
            </div>
    }

    return (
        <div className={styles.wrapper}>
            <Link to='./'><img className={styles.logo} src={rcfcLogo} /></Link>
            <div className={`${styles.settingsSection}`}>
                <select className={styles.langSelect} id="language" name="language" onChange={ event => { selectLanguage(event) }} defaultValue={ getLanguage() }>
                    <option className={`${styles.option} ${styles.descriptionFontZhHK}`} value="zh-HK">繁體中文</option>
                    <option className={`${styles.option} ${styles.descriptionFontEn}`} value="en">English</option>
                </select>

                {window.location.pathname == "/" &&
                <div className={`${styles.howItWorksButton} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.descriptionFontEn}`} onClick={()=>{ document.getElementById('howItWorks')?.scrollIntoView({behavior: "smooth"}) }}>
                    <Trans i18nKey="titlebar.howItWorks" > </Trans>
                </div>}

                {window.location.pathname == "/" &&
                <div className={`${styles.faqButton} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.descriptionFontEn}`} onClick={()=>{ history.push('./faq') }}>
                    <Trans i18nKey="titlebar.faq" > </Trans>
                </div>}
                {!isMobile && getWalletSection()}
            </div>
        </div>
    )
}

export default TitleBar