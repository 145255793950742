import React, { useEffect, useState } from 'react'
import TitleBar from '../../titleBar/TitleBar'
import styles from './WalletPage.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import metaMaskLogo from '../../images/logo/Logo_MetaMask_256h.png'
import portisLogo from '../../images/logo/Logo_Portis_256h.png'
import AppContextProvider, { useAppContext, useAppUpdateContext } from '../../contexts/AppContextProvider'
// This function detects most providers injected at window.ethereum
import detectEthereumProvider from '@metamask/detect-provider';
import Portis from '@portis/web3';
import Web3 from 'web3';
import { usePopupContext, usePopupUpdateContext } from '../../contexts/PopupContextProvider'
import LoadingPopup from '../../popups/loadingPopup/LoadingPopup'
import { useOpenSeaContext, useOpenSeaUpdateContext } from '../../contexts/OpenSeaContextProvider'
import { Network, OpenSeaPort } from 'opensea-js'
import howIcon from './images/how_icon.png'
import howIconWhite from './images/how_icon_white.png'
import loadingIcon from './images/loading.png';
import { useConnectWalletMessagePopupContext } from '../../contexts/popups/ConnectWalletMessagePopupContextProvider'
import { usePortisContext } from '../../contexts/wallets/PortisContextProvider'
import { useWeb3Context } from '../../contexts/Web3ContextProvider'
import { useHistory } from 'react-router'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import MobileAlertView from '../../common/mobileAlertView/MobileAlertView'
import GenericButton from '../../common/genericButton/GenericButton'
import {env, portisAppid, openseaAppid, alchemyRinkebyApi, alchemyMainnetApi } from '../../secrets.json'

function WalletPage() {
    const { t, i18n } = useTranslation()
    const appContext = useAppContext()
    const appUpdateContext = useAppUpdateContext()
    const popupContext = usePopupContext()
    const popupUpdateContext = usePopupUpdateContext()
    const openSeaContext = useOpenSeaContext()
    const openSeaUpdateContext = useOpenSeaUpdateContext()
    const connectWalletMessagePopupContext = useConnectWalletMessagePopupContext()
    const portisContext = usePortisContext()
    const web3Context = useWeb3Context()
    const [metaMaskLoading, setMetaMaskLoading] = useState(false)
    const [portisLoading, setPortisLoading] = useState(false)
    const history = useHistory()
    const isEnvMainnet = (env == "mainnet")

    useEffect(() => {
        window.scroll(0, 0)
        return () => {

        }
    }, [])

    const connectMetaMask = async () => {
        console.log("connect MetaMask")

        //const provider = (window as any).ethereum
        try {
            setMetaMaskLoading(true)

            const provider: any = await detectEthereumProvider({
                mustBeMetaMask: true
            })

            if (provider) {

                // If the provider returned by detectEthereumProvider is not the same as
                // window.ethereum, something is overwriting it, perhaps another wallet.
                if (provider !== window.ethereum) {
                    console.error('Do you have multiple wallets installed?');
                    return
                }

                // Access the decentralized web!
                var accounts = await provider.request({ method: 'eth_requestAccounts'})
                appUpdateContext?.setAppUpdateContent("MetaMask", accounts)

                if (provider) {
                    var newOpenSeaPort : OpenSeaPort = new OpenSeaPort(provider, {
                        networkName: isEnvMainnet? Network.Main : Network.Rinkeby,
                        // apiKey: opensea_appid
                    })
                    openSeaUpdateContext?.setOpenSeaContent(newOpenSeaPort)
                }
                sessionStorage.setItem("wallet_type", "MetaMask")
                const web3 = new Web3(provider)
                web3Context?.setWeb3(web3)
                history.push("./collection")
            } else {
                console.log("No Provider")
                window.open("https://metamask.io/download.html");
            }
        } catch (e) {
            console.log('Error ' + e)
        } finally {
            setMetaMaskLoading(false)
        }
    }

    const connectPortis = async () => {

        console.log("connect Portis")
        setPortisLoading(true)
        try {
            const portis = new Portis(portisAppid, isEnvMainnet? 'mainnet' : 'rinkeby')
            const provider = portis.provider
            const web3 = new Web3(provider)

            web3.eth.getAccounts((error, accounts) => {
                if (error) {
                    console.log('error ' + JSON.stringify(error))
                    setPortisLoading(false)
                    return
                }

                console.log("Portis " + JSON.stringify(accounts))
                appUpdateContext?.setAppUpdateContent("Portis", accounts)
                portisContext?.setPortis(portis)
                web3Context?.setWeb3(web3)
                sessionStorage.setItem("wallet_type", "Portis")

                var newOpenSeaPort : OpenSeaPort = new OpenSeaPort(provider, {
                    networkName: isEnvMainnet? Network.Main : Network.Rinkeby,
                    // apiKey: opensea_appid
                })
                openSeaUpdateContext?.setOpenSeaContent(newOpenSeaPort)
                history.push("./collection")
                setPortisLoading(false)
            })

        } catch (e) {
            console.log("e " + JSON.stringify(e))
        } finally {
            // console.log("finally")
        }
    }

    const showMessagePopup = () => {
        window.open(t<string>('mediumLinks.setUpWallet'))
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.contentWrapper}>
            <TitleBar/>
            <MobileView>
                <MobileAlertView></MobileAlertView>
            </MobileView>
            {!isMobile && <div>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}><Trans i18nKey='wallet.title'></Trans></div>
                </div>
                <div className={styles.walletWrapper}>
                    {appContext?.walletType != "MetaMask" && <div className={styles.walletSection}>
                        <div className={styles.logo}><img src={metaMaskLogo} height={96}/></div>
                        <div className={styles.description}><Trans i18nKey='wallet.metamask.description'></Trans></div>
                        {metaMaskLoading?<div className={styles.loadingIconWrapper}> <img width="128" height="128" className={styles.loadingIcon} src={loadingIcon} /> </div> :
                        <div className={styles.ctaButtonSection}>
                            <div className={styles.ctaButtonWrapper}><GenericButton value={t<string>('wallet.signIn')} onClick={()=>{ connectMetaMask() }} center ={true}/></div>
                            <div className={styles.createWallet}>
                                <img className={styles.howIcon} src={howIcon} onClick={() => { showMessagePopup() }}/>
                                <div className={styles.createWalletLink}>
                                    <Trans i18nKey='wallet.createMetaMask'></Trans>
                                    <input type="button" className={styles.createButton} value={t<string>('wallet.create')} onClick={()=>connectMetaMask()}/>
                                </div>
                            </div>
                        </div>
                        }
                        {
                            portisLoading && <div className={styles.overlay}></div>
                        }
                    </div>}
                    {appContext?.walletType != "Portis" && <div className={styles.walletSection}>
                        <div className={styles.logo}><img src={portisLogo} height={128}/></div>
                        <div className={styles.description}><Trans i18nKey='wallet.portis.description'></Trans></div>
                        {portisLoading?<div className={styles.loadingIconWrapper}> <img width="96" height="96" className={styles.loadingIcon} src={loadingIcon} /> </div> :
                        <div className={styles.ctaButtonSection}>
                            <div className={styles.ctaButtonWrapper}><GenericButton value={t<string>('wallet.signIn')} onClick={()=>{ connectPortis() }} center ={true}/></div>
                            <div className={styles.createWallet}>
                                <img className={styles.howIcon} src={howIcon} onClick={() => { showMessagePopup() }}/>
                                <div className={styles.createWalletLink}>
                                    <Trans i18nKey='wallet.createPortis'></Trans>
                                    <input type="button" className={styles.createButton} value={t<string>('wallet.create')} onClick={()=>connectPortis()}/>
                                </div>
                            </div>
                        </div>}
                        {
                            metaMaskLoading && <div className={styles.overlay}></div>
                        }
                    </div>}
                </div>
                <div className={styles.howToConnect}>
                    <img className={styles.howIconWhite} src={howIconWhite} onClick={() => { showMessagePopup() }}/>
                    <input type="button" className={styles.howToButton} value={t<string>('connectWalletPopup.howToConnect')} onClick={()=> showMessagePopup()}/>
                </div>
                </div>}
            </div>
        </div>
    )
}

export default WalletPage