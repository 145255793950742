import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { useContext, useState } from 'react'

export type WrapEthPopupContent = {
    isShowing: boolean,
    show: (popupContent: boolean) => void
}

const WrapEthPopupContext = React.createContext<WrapEthPopupContent | undefined>(undefined)

export function useWrapEthPopupContext() {
    return useContext(WrapEthPopupContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function WrapEthPopupContextProvider(props: Props) {
    const [popupContent, setPopupContent] = useState<WrapEthPopupContent>({
        isShowing: false,
        show: (shouldShow: boolean) => {
            const targetElement = document.querySelector('body');
            if (shouldShow) {
                if (targetElement) {
                    disableBodyScroll(targetElement);
                }
            } else {
                if (targetElement) {
                    enableBodyScroll(targetElement);
                }
            }
            setPopupContent(
                {...popupContent, isShowing: shouldShow}
            )
        }
    })

    return (
        <WrapEthPopupContext.Provider value={popupContent}>
            {props.children}
        </WrapEthPopupContext.Provider>
    )
}

export default WrapEthPopupContextProvider
