import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styles from "./WalletActionDropdown.module.scss"

function WalletActionDropdown({logout, switchWallet } : { logout: () => void, switchWallet: () => void} ) {
    const [t, i18n] = useTranslation()
    const history = useHistory()
    const gotoCollection = () => {
        history.push('/collection')
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={`${styles.wrapper}`}>
                <input type="button" className={`${styles.selection} ${styles.firstSelection} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.descriptionFontEn}`} value={t<string>('walletActionDropdown.gotoCollection')} onClick={() => { gotoCollection() }}/>
                <input type="button" className={`${styles.selection} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.descriptionFontEn}`} value={t<string>('walletActionDropdown.logout')} onClick={() => { logout() }}/>
                <input type="button" className={`${styles.selection} ${styles.lastSelection} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.descriptionFontEn}`} value={t<string>('walletActionDropdown.switch')} onClick={() => { switchWallet()}}/>
            </div>
        </div>
    )
}

export default WalletActionDropdown
