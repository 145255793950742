import styles from './BidItemDetailsPage.module.scss'
import { OpenSeaAsset, Order, WyvernSchemaName } from 'opensea-js/lib/types'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import BidItemImage from '../../common/bidItemImage/BidItemImage'
import DateCounter from '../../common/dateCounter/DateCounter'
import {IsExpired} from '../../common/dateCounter/DateCounter'
import GenericButton from '../../common/genericButton/GenericButton'
import Loading from '../../common/loading/Loading'
import Price from '../../common/price/Price'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../contexts/OpenSeaContextProvider'
import { useConnectWalletPopupContext } from '../../contexts/popups/ConnectWalletPopupContextProvider'
import { usePlaceBidPopupContext } from '../../contexts/popups/PlaceBidPopupContextProvider'
import TitleBar from '../../titleBar/TitleBar'
import lcMp4 from '../../images/RCFC_2021_BEST_1_LS.mp4'
import {MobileView, isMobile } from 'react-device-detect'
import MobileAlertView from '../../common/mobileAlertView/MobileAlertView'
import HowTo from '../../common/howTo/HowTo'
import {bid_start_month, bid_start_day, bid_start_hour, bid_start_minute } from '../../AppSettings.json'
import {bid_end_month, bid_end_day, bid_end_hour, bid_end_minute } from '../../AppSettings.json'
import {env, assetAddressMainnet, assetAddressRinkeby, auctionIdMainnet, auctionIdRinkeby } from '../../secrets.json'
import { delay } from 'opensea-js/lib/utils/utils'

function BidItemDetailsPage() {
    const statusOptions = {
        startSoon : "startSoon",
        started : "started",
        extended : "extended",
        ended : "ended"
    } 
    const settingOptions = ["started" ,"extended" , "ended"]


    const { t, i18n } = useTranslation()
    const openSeaContext = useOpenSeaContext()
    const placeBidPopupContext = usePlaceBidPopupContext()
    const connectWalletPopupContext = useConnectWalletPopupContext()
    const [bidItem, setBidItem] = useState<OpenSeaAsset | undefined>(undefined)
    const appContext = useAppContext()

    const [bidStatus , setBidStatus] = useState<string>(statusOptions.startSoon)
    const [bidButtonText , setBidButtonText] = useState<string>(t<string>('bidItemDetailsPage.ctaButton.title'))
    const [ isStarted , setIsStarted ] = useState<boolean>(false)
    const [ isExpired , setIsExpired ] = useState<boolean>(false)
    const isEnvMainnet = (env == "mainnet")

    useEffect(() => {
        const interval  = setInterval(() => { 
            let start = IsExpired( {year:2021,  month:bid_start_month-1, day:bid_start_day , hour:bid_start_hour,  minute:bid_start_minute , second:0} );
            let expired = IsExpired( {year:2021,  month:bid_end_month-1, day:bid_end_day , hour:bid_end_hour,  minute:bid_end_minute , second:0} );
            setIsStarted(start);
            setIsExpired(expired) 
        } , 1000 ) 
        return () => clearInterval(interval)
    } , [] )

    useEffect(() => {
        updateStatus()
    } , [appContext?.bidEnable , isExpired , isStarted , appContext?.expiration , i18n.language ] )

    function updateStatus() {
        let onSellOrder = appContext?.expiration != 0
        let serverSetting =  (appContext?.bidEnable != null && settingOptions.includes(appContext.bidEnable))?  appContext.bidEnable : "";

        if (serverSetting != "") {
            setBidStatus(serverSetting)
            setBidButtonText(t<string>('bidItemDetailsPage.ctaButton.' + serverSetting))
            return;
        } 
        if (!isStarted) { 
            setBidStatus(statusOptions.startSoon)
            setBidButtonText(t<string>('bidItemDetailsPage.ctaButton.' + statusOptions.startSoon))
            return
        }
        if (!isExpired) {
            setBidStatus(statusOptions.started)
            setBidButtonText(t<string>('bidItemDetailsPage.ctaButton.' + statusOptions.started))
            return
        }
        if (onSellOrder) {
            setBidStatus(statusOptions.extended)
            setBidButtonText(t<string>('bidItemDetailsPage.ctaButton.' + statusOptions.extended))
            return
        }
        setBidStatus(statusOptions.ended)
        setBidButtonText(t<string>('bidItemDetailsPage.ctaButton.' + statusOptions.ended))
    }

    useEffect(() => {
        var tokenAddress = isEnvMainnet? assetAddressMainnet : assetAddressRinkeby;
        var tokenId = isEnvMainnet? auctionIdMainnet : auctionIdRinkeby;

        async function getAsset() {
            var asset = undefined;
            var retry = 0;
            while (asset == undefined && retry <= 10) {
                retry ++;
                try {
                    asset = await openSeaContext?.api.getAsset({
                        "tokenAddress": tokenAddress,
                        "tokenId": tokenId
                    })
                    if (asset && asset.buyOrders){
                        var sortedOrder = asset.buyOrders.sort( (a,b) => { return b.basePrice.toNumber() - a.basePrice.toNumber()} );
                        asset.buyOrders = sortedOrder;
                    }
                    if (asset) {
                        setBidItem(asset)
                    }
                } catch (e) {
                    console.log("BidItemDetails Error " + JSON.stringify(e, null, 2))
                } 
                await delay(1000);
            }
        }
        getAsset()
        return () => {
        }
    }, [appContext?.bidPrice])

    const getAccountAddress = (address: string | undefined) => {
        if (address) {
            var abbrAddress = address.substr(0,6) + "..." + address.substr(address.length - 4)
            return abbrAddress
        }
        return ""
    }

    const getCreatedTime = (o : Order) => {
        var ct = o.createdTime
        if (ct) {
            var date = new Date(ct.toNumber() * 1000)
            return date.toDateString()
        }
        return ""
    }

    const onClick = async () => {
        if (appContext?.accounts) {
            placeBidPopupContext?.show(true)
            //wrapEthPopupContext?.show(true)
        } else {
            connectWalletPopupContext?.show(true)
        }
    }



    return (
        <div className={styles.bgWrapper}>
            <TitleBar/>
            <MobileView>
                <MobileAlertView/>
            </MobileView>
            {!isMobile && <div className={styles.contentWrapper}>
                <div className={styles.informationSection}>
                    <div className={styles.videoWrapper}>
                        <video className={styles.videoContent} src={lcMp4} autoPlay={true} width={480} height={480} loop={true} muted/>
                    </div>
                    <div className={styles.informationWrapper}>
                        <div className={styles.logoWrapper}>
                        <BidItemImage/>
                        </div>
                        <div className={`${styles.descriptionWrapper} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                            <Trans i18nKey='bidItemDetailsPage.description'></Trans>
                        </div>
                        <div className={styles.titleWrapper}>
                            <div className={`${styles.history} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <Trans i18nKey='bidItemDetailsPage.history'></Trans>
                                <hr className={styles.line}/>
                            </div>
                            <HowTo text={t<string>('bidItemDetailsPage.howToBid')} link={t<string>('mediumLinks.howToBuy')}></HowTo>
                        </div>
                        <div className={styles.currentBidsWrapper}>
                            {bidItem?.buyOrders?.length == 0 && <div className={styles.buyOrder}> {t<string>('bidItemDetailsPage.noHistory')}</div>}
                            {bidItem? bidItem.buyOrders?.map((buyOrder , key) => {
                                return ( buyOrder && key < 5 ) && <div className={styles.buyOrder}>
                                    <div className={`${styles.bidTaker} ${styles.descriptionFontEn}`}>{getAccountAddress(buyOrder?.makerAccount?.address)}</div>
                                    <div className={`${styles.bidPrice} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}><Trans i18nKey="bidItemDetailsPage.buyOrder.bid"></Trans>{buyOrder?.basePrice.shift(-18).toString()} WETH</div>
                                    <div className={`${styles.bidTime} ${styles.descriptionFontEn}`}>{getCreatedTime(buyOrder)} </div>
                                </div>
                            }) : <Loading width={96} height={96}/> }

                            { ( bidItem?.buyOrders != null &&  bidItem.buyOrders.length > 5 ) && 
                            <div className={`${styles.buyOrder} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}> 
                            {<Trans i18nKey='bidItemDetailsPage.have'></Trans>} {bidItem.buyOrders.length - 5} {<Trans i18nKey='bidItemDetailsPage.more'></Trans>} </div>}
                        </div>
                    </div>
                </div>
                <div className={`${styles.timePriceSection} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                    { (bidStatus == statusOptions.started ) && 
                        <div className={styles.endAfter}>
                        <Trans i18nKey="bidItemDetailsPage.endAfter"></Trans>
                    </div> }
                    { (bidStatus == statusOptions.started ) && 
                        <div className={styles.countdown}>
                        <DateCounter year={2021} month={bid_end_month-1} day={bid_end_day} hour={bid_end_hour} minute={bid_end_minute} second={0}/>
                    </div>}
                    { (bidStatus == statusOptions.extended )  && <div className={styles.endSoon}>
                        <Trans i18nKey="bidItemDetailsPage.endSoon"></Trans>
                    </div> }
                    <div className={styles.ctaWrapper}>
                        <GenericButton enabled={(bidStatus == statusOptions.started || bidStatus == statusOptions.extended )} onClick={()=>{onClick()}} value={bidButtonText}/>
                    </div>
                    <div className={styles.priceWrapper}>
                        {appContext != null && <Price eth={appContext?.bidPrice}/>}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default BidItemDetailsPage
