import companyLogo from '../images/logo/company_logo.svg'
import facebookLogo from '../images/logo/Button_Facebook.svg'
import twitterLogo from '../images/logo/Button_Twitter.svg'
import telegramLogo from '../images/logo/Button_Telegram.svg'
import mediumLogo from '../images/logo/Button_Medium.svg'
import styles from './Footer.module.scss'
import { Trans, useTranslation } from 'react-i18next'

function Footer() {

    const [t, i18n] = useTranslation()
    return (
        <div className={styles.wrapper}>
            <div className={styles.logoSection}>
                <img className={styles.companyLogo} src={companyLogo}/>
            </div>
            <div className={styles.socialMediaSection}>
                <a className={styles.logoWrapper} href="https://medium.com/hk-1-football-nft" target="_blank"><img className={styles.logo} src={mediumLogo} alt="medium"/></a>
                <a className={styles.logoWrapper} href="https://t.me/hkfootballnft" target="_blank"><img className={styles.logo} src={telegramLogo} alt="telegram"/></a>
                <a className={styles.logoWrapper} href="https://www.facebook.com/hkfootballnft/" target="_blank"><img className={styles.logo} src={facebookLogo} alt="facebook"/></a>
                <a className={styles.logoWrapper} href="https://twitter.com/hkfootballnft" target="_blank"><img className={styles.logo} src={twitterLogo} alt="twitter"/></a>
            </div>
            <div className={`${styles.policySection} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.descriptionFontEn}`}>
                <a className={styles.policy} href="./privacy/" target="_blank"><Trans i18nKey='footer.privacy_policy'></Trans></a>
                <a href="mailto:contact@olivex.ai" className={styles.policy}><Trans i18nKey='footer.contact_us'></Trans></a>
            </div>
            <div className={`${styles.copyRightSection} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.descriptionFontEn}`}>
                <Trans i18nKey='footer.copy_right'></Trans>
            </div>
        </div>
    )
}

export default Footer