import { useTranslation } from 'react-i18next'
import loadingIcon from './images/loading.png'
import styles from './Loading.module.scss'

function Loading({ width, height} : { width : number, height: number}) {
    const [t] = useTranslation()
    return (
        <div className={styles.wrapper}>
            <img className={styles.loadingIcon} src={loadingIcon} width={width} height={height} alt={t<string>("howTo")}/>
        </div>
    )
}

export default Loading
