import { useTranslation } from 'react-i18next';
import styles from './GenericButton.module.scss'

function GenericButton({onClick, value, enabled = true, hollow, center = true} : {onClick: () => void, value: string, enabled? : boolean , hollow? : boolean, center? : boolean}) {
    const { t, i18n } = useTranslation()

    const getButtonName = (center: boolean | undefined) => {
        return center? styles.ctaButtonCenter : styles.ctaButton
    }

    const getButtonFrameName = (enabled: boolean | undefined , hollow: boolean | undefined) => {
        return enabled? hollow? styles.ctaButtonHollowFrame : styles.ctaButtonFrame : styles.disabledCtaButtonFrame 
    }

    return (
        <div className={getButtonName(center)} >
            <div className={styles.ctaButtonWrapper}>
                <div className={getButtonFrameName(enabled , hollow)}></div>
                {enabled  &&  <div className={`${styles.text} ${i18n.language == 'en' ? styles.descriptionFontEn : styles.titleFontZhHK}`} onClick= { () => { onClick() }} > {value}</div> }
                {!enabled &&  <div className={`${styles.disabledText} ${i18n.language == 'en' ? styles.descriptionFontEn : styles.titleFontZhHK}`} > {value}</div> }
            </div>
        </div>
    )
}

export default GenericButton
