import React from 'react'
import styles from './termsAndConditions.module.scss'
import { Trans, useTranslation } from 'react-i18next'

function onClick (){
    window.open('https://opensea.io/tos')
}

function TermsAndConditions ({text} : {text: string}) {
    const [t, i18n] = useTranslation()
    return ( <div className={styles.wrapper} >
                <div className={`${styles.detail} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`} >{text} </div> 
                <div className={`${styles.terms} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`} onClick={() => onClick()} >&nbsp;{<Trans i18nKey="termsAndConditions"></Trans>}</div> 
            </div>
    )
}

export default TermsAndConditions