import React from 'react'
import styles from './HowTo.module.scss'
import alertCircle from "../../images/alert-circle.png"
import { useTranslation } from 'react-i18next'

function HowTo({text, link} : {text: string, link:string}) {
    const [t, i18n] = useTranslation()
    return (
        <div className={styles.howToBid} onClick={() => { window.open(link) }}>
            <img className={styles.logo} src={alertCircle} alt={t<string>("howTo")}/>
            <div className={`${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>{text}</div>
        </div>
    )
}

export default HowTo