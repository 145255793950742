import { useEffect } from 'react';
import styles from './HomePage.module.scss'
import { Trans, useTranslation } from 'react-i18next';
import TitleBar from '../../titleBar/TitleBar';
import players from './images/Banner_Intro.png'
import rcfcLogo from '../../images/logo/rcfc_logo.png'
import { usePopupContext } from '../../contexts/PopupContextProvider';
import { useAppContext, useAppUpdateContext } from '../../contexts/AppContextProvider';
import detectEthereumProvider from '@metamask/detect-provider';
import { Network, OpenSeaPort } from 'opensea-js';
import { useOpenSeaUpdateContext } from '../../contexts/OpenSeaContextProvider';
import GenericButton from '../../common/genericButton/GenericButton';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
import i18next from 'i18next';
import { Console } from 'console';

function HomePage() {
    const { t, i18n } = useTranslation()
    const appUpdateContext = useAppUpdateContext()
    const openSeaUpdateContext = useOpenSeaUpdateContext()
    const history = useHistory()

    // useEffect(() => {
    //     var walletType = sessionStorage.getItem("wallet_type")
    //     async function initialize() {
    //     }
    //     initialize()
    // }, [])

    const connectWallet = () => {
        history.push("/wallet")
    }

    return (
        <div className={styles.bgWrapper}>
                <TitleBar/>
            <div className={styles.contentWrapper}>
                <img className={styles.players} src={players}/>
                <div className={styles.contentSection}>
                    {i18n.language === 'zh-HK' && (
                        <div className={`${styles.title_zh_HK} ${styles.titleFontZhHK}`}>
                            <div className={styles.titleFontZhHK}><Trans i18nKey='homepage.title.part1'></Trans></div>
                            <div><span className={`${styles.part2} ${styles.titleFontZhHK}`}><Trans i18nKey='homepage.title.part2'></Trans></span></div>
                        </div>
                    )}
                    {i18n.language === 'en' && (
                        <div className={styles.title}>
                            <div className={`${styles.titleFontEn}`}><Trans i18nKey='homepage.title.part1'></Trans></div>
                            <div><span className={`${styles.part2} ${styles.titleFontEn}`}><Trans i18nKey='homepage.title.part2'></Trans></span></div>
                            <div className={`${styles.titleFontEn}`}><Trans i18nKey='homepage.title.part3'></Trans></div>
                        </div>
                    )}
                    <div className={styles.description}>
                        <div className={`${styles.part2} ${i18n.language == 'en' ? styles.descriptionFontEn : styles.descriptionFontZhHK}`}><Trans i18nKey='homepage.description.part1'></Trans></div>
                        <div className={`${styles.partnerSection} ${i18n.language == 'en' ? styles.descriptionFontEn : styles.descriptionFontZhHK}`}>
                            <div className={`${styles.partnerDescription} ${i18n.language == 'en' ? styles.descriptionFontEn : styles.descriptionFontZhHK}`}><Trans i18nKey='homepage.description.part4'></Trans></div>
                            <img src={rcfcLogo}/>
                        </div>
                    </div>
                    {!isMobile && <div className={styles.ctaButtons}>
                        {(sessionStorage.getItem("wallet_type") == null || sessionStorage.getItem("wallet_type") == '' ) &&
                            <div className={styles.ctaButtonWrapper}><GenericButton value={t<string>('homepage.ctaButton.connectWallet')} onClick={()=>{ connectWallet() }}/></div>
                        }
                        <div className={styles.ctaButtonWrapper}><GenericButton hollow={true} value={t<string>('homepage.ctaButton.howToStart')} 
                            onClick={()=>{ document.getElementById('howItWorks')?.scrollIntoView({behavior: "smooth"})}}/>
                        </div>
                    </div> }
                </div>
            </div>
        </div>
    )
}

export default HomePage