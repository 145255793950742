import { OpenSeaAsset, OpenSeaAssetQuery } from 'opensea-js/lib/types'
import { useEffect, useState } from 'react'
import { isMobile, MobileView } from 'react-device-detect'
import { Trans, useTranslation } from 'react-i18next'
import Loading from '../../common/loading/Loading'
import MobileAlertView from '../../common/mobileAlertView/MobileAlertView'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../contexts/OpenSeaContextProvider'
import TitleBar from '../../titleBar/TitleBar'
import styles from "./CollectionPage.module.scss"
import {env, assetAddressRinkeby, assetAddressMainnet } from '../../secrets.json'
import axios from 'axios'
import { delay } from 'opensea-js/lib/utils/utils'
import DotLoading from '../../common/dotLoading/DotLoading'


function CollectionPage() {
    const [t, i18n] = useTranslation()
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState<collectionDisplay[] | undefined>(undefined)
    const openSea = useOpenSeaContext()
    const appContext = useAppContext()
    const isEnvMainnet = (env == "mainnet")
    const dummyName = ["RCFC1" , "DUMMY1"]
    

    interface collectionDisplay {
        tokenId: string | null;
        imagePreviewUrl: string;
        name: string;
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        async function getAssets() {
            if (appContext?.accounts) {
                setLoading(true)
                var itemList : collectionDisplay[] = []
                var result :string[] = []
                var ownedUrl = isEnvMainnet?  "https://api.hkfootballnft.com/v1/tokenowned/": "https://api.hkfootballnft.com/tokenowned/"
                var respones = await axios.get(ownedUrl+appContext.accounts[0] )
                if (respones.data != null) { result = respones.data.tokenIds }
                var jsonUrl = isEnvMainnet?  "https://api.hkfootballnft.com/data/": "https://api.hkfootballnft.com/json/"
                if (result != null){
                    for ( var tokenId of result ){
                        var item :collectionDisplay|undefined = undefined
                        try{
                            var serverData = await axios.get(jsonUrl + tokenId)
                            item  = {
                                name : serverData.data.name,
                                imagePreviewUrl : serverData.data.image,
                                tokenId : tokenId
                            }
                        } catch (e){ console.log(e);}
                        if (item != undefined) {
                            itemList.push(item);
                        }
                    }
                }
                itemList = itemList.sort((a,b) => (a.name == b.name ) ? 0 : (a.name > b.name) ? 1 : -1 );
                setItems(itemList)
                setLoading(false)
            } else {

            }
        }
        getAssets()
        return () => {

        }
    }, [appContext?.accounts, appContext?.walletType])

    const haveDummy = ()=> {
        if (items != null )
        for ( var item of items){
            if (dummyName.some(a => (a == item.name ))) {
                return true
            }
        return false;
        }
    }      

    return (
        <div className={styles.parentWrapper}>
            <TitleBar/>
            <MobileView>
                <MobileAlertView></MobileAlertView>
            </MobileView>
            {!isMobile &&
            <div className={styles.contentWrapper}>
                <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                    <Trans i18nKey="collectionPage.title"></Trans>
                </div>
                {haveDummy() && 
                <div className={`${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}><Trans i18nKey="collectionPage.dummyShown"></Trans></div>
                }
                <div className={styles.collectionWrapper}>
                    {loading && <div className={styles.loadingWrapper}><Loading width={128} height={128}/></div> }
                    {(!loading && items && items.length > 0 ) &&  <div className={styles.itemList}>
                        {items?.map((item, key) => {
                            return <div className={styles.itemWrapper} key={key}>
                                    <div className={styles.image} ><img src={item.imagePreviewUrl} height= {300}/></div>
                                    <div className={styles.descriptionWrapper}>
                                        { dummyName.some(a => (a == item.name )) ? 
                            <div className={styles.loading}>
                            <DotLoading width={16} height={16} borderRadius={16}/>
                        </div>
                                        :
                                        <div className={`${styles.name} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>{item.name}</div>
                                        }
                                    </div>
                                </div>
                            })}
                    </div>}
                    {(!loading && (!items || items.length == 0 )) && <div className={styles.noItemMsgWrapper}>
                        <div className={`${styles.noItemMsg} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}><Trans i18nKey="collectionPage.noItemMsg"></Trans></div>
                    </div>}
                </div>
            </div>}
        </div>
    )
}

export default CollectionPage