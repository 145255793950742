import React from 'react'
import styles from "./HowItWorksSection.module.scss"
import bgLineImg from './images/HowItWorks_Bg_Line.png'
import bgImg from './images/HowItWorks_Bg.png'
import step1EnImg from './images/HowItWorks_Step1_EN.png'
import step2EnImg from './images/HowItWorks_Step2_EN.png'
import step3EnImg from './images/HowItWorks_Step3_EN.png'
import step1TCImg from './images/HowItWorks_Step1_TC.png'
import step2TCImg from './images/HowItWorks_Step2_TC.png'
import step3TCImg from './images/HowItWorks_Step3_TC.png'
import { Trans, useTranslation } from 'react-i18next'
import HowTo from '../../../common/howTo/HowTo'

function HowItWorksSection() {
    const {t, i18n} = useTranslation()

    return (
        <div className={styles.bgWrapper} id='howItWorks'>
            <div className={styles.contentWrapper}>
            <div className={`${styles.titleSection} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.titleFontEn}`}>
                <div className={styles.part1}><Trans i18nKey="howItWorks.title.part1"></Trans></div>
                <div className={styles.part2}><Trans i18nKey="howItWorks.title.part2"></Trans></div>
            </div>
            <div>
                <div className={styles.stepSection}>
                    <img className={styles.bgLine} src={bgLineImg} />
                    <div className={`${styles.stepWrapper} ${styles.step1Wrapper}`}>
                        <div className={styles.stepImageWrapper}>
                            {i18n.language === "zh-HK" && <img className={styles.stepImage} src={step1TCImg}/>}
                            {i18n.language === "en" && <img className={styles.stepImage} src={step1EnImg}/>}
                        </div>
                        <div className={styles.descriptionWrapper}>
                            <div className={`${styles.title} ${styles.stepTitle} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <div><Trans i18nKey="howItWorks.steps.step1.title"></Trans></div>
                            </div>
                            <div className={`${styles.description} ${styles.stepDescription} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <div><Trans i18nKey="howItWorks.steps.step1.description"></Trans></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.stepWrapper} ${styles.step2Wrapper}`}>
                        <div className={styles.stepImageWrapper}>
                            {i18n.language === "zh-HK" && <img className={styles.stepImage} src={step2TCImg}/>}
                            {i18n.language === "en" && <img className={styles.stepImage} src={step2EnImg}/>}
                        </div>
                        <div className={styles.descriptionWrapper}>
                            <div className={`${styles.title} ${styles.stepTitle} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <div><Trans i18nKey="howItWorks.steps.step2.title"></Trans></div>
                            </div>
                            <div className={`${styles.description} ${styles.stepDescription} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <div><Trans i18nKey="howItWorks.steps.step2.description"></Trans></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.stepWrapper} ${styles.step3Wrapper}`}>
                        <div className={styles.stepImageWrapper}>
                            {i18n.language === "zh-HK" && <img className={styles.stepImage} src={step3TCImg}/>}
                            {i18n.language === "en" && <img className={styles.stepImage} src={step3EnImg}/>}
                        </div>
                        <div className={styles.descriptionWrapper}>
                            <div className={`${styles.title} ${styles.stepTitle} ${i18n.language == 'zh-HK' ? styles.titleFontZhHK : styles.titleFontEn}`}>
                                <div><Trans i18nKey="howItWorks.steps.step3.title"></Trans></div>
                            </div>
                            <div className={`${styles.description} ${styles.stepDescription} ${i18n.language == 'zh-HK' ? styles.descriptionFontZhHK : styles.descriptionFontEn}`}>
                                <div><Trans i18nKey="howItWorks.steps.step3.description"></Trans></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className={styles.howToWrapper}><HowTo text={t<string>('howItWorks.howToSetup')} link={t<string>('mediumLinks.setUpWallet')} /></div>
            </div>
        </div>
    )
}

export default HowItWorksSection
