import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationZH from './locales/zh-HK/translation.json';

const resources = {  'en': {    translation: translationEN  },  'zh-HK': {    translation: translationZH  }};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources,
    lng: 'zh-HK',
    fallbackLng: {'zh-HK' : ['en'] },
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

  export default i18n;