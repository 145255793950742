import { OpenSeaAsset, OpenSeaAssetQuery } from 'opensea-js/lib/types'
import { useEffect, useState } from 'react'
import { isMobile, MobileView } from 'react-device-detect'
import { Trans , useTranslation} from 'react-i18next'
import TitleBar from '../../titleBar/TitleBar'
import styles from "./FaqPage.module.scss"
import GenericButton from '../../common/genericButton/GenericButton'

interface faqCell  {
    id: number;
    question: string;
    answer: string;
}

function FaqPage() {
    const [items, setItems] = useState<faqCell[] | undefined>(undefined)
    const { t, i18n } = useTranslation()
    const [section, setSection] = useState<string>("nft");
    const [ questionId, setQuestionId] = useState<number>(0);

    useEffect(() => {
        window.scrollTo(0, 0)  
    }, [])

    useEffect(() => {
        var result = [];
        let i = 1;
        while ((i18n.exists(((section=="nft")? "faq.nftQuestions." : "faq.newQuestions." ) + i)) && (i18n.exists( ((section=="nft")? "faq.nftAnswers." : "faq.newAnswers." ) + i))){ 
            var cell : faqCell = {
                id:i, 
                question:t<string>( ((section=="nft")? "faq.nftQuestions." : "faq.newQuestions." ) + i), 
                answer:t<string>( ((section=="nft")? "faq.nftAnswers." : "faq.newAnswers." ) + i), 
            };
            result.push(cell);
            i++;
            // console.log(i + section);
        }
        setItems(result);
    }, [i18n.language , section ])

    useEffect(() => {
        setQuestionId(0);
    }, [ section ])


    return ( <div className={styles.parentWrapper}>
        <TitleBar/>
        <div className={styles.titleWrapper}>
            <div className={`${styles.title} ${i18n.language=="zh-HK"? styles.titleFontZhHK : styles.titleFontEn}`}>
                <Trans i18nKey="faq.title"></Trans>
            </div>
            <div className={styles.bodyWrapper}>
                <div className={styles.sectionButtons}>
                    <div className={styles.buttonWrapper}> <GenericButton hollow={(section!="nft")} onClick={()=>{setSection("nft")}} value={t<string>('faq.nft')}/> </div>
                    <div className={styles.buttonWrapper}> <GenericButton hollow={(section=="nft")} onClick={()=>{setSection("new")}} value={t<string>('faq.new')}/> </div>
                </div>
                <div className={styles.itemListWrapper}>
                    <div className={styles.itemList}>
                        {items?.map((item, key) => {
                            return <div className={styles.cell} key={key}>
                                <div className={`${styles.id} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`} key={key}> {item.id}{'.'} </div>
                                <div className={styles.cellBody}>
                                    <div className={styles.questionWrapper} >
                                        <div className={`${styles.question} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`} > {item.question} </div>
                                        <div className={`${styles.plusButton} ${styles.descriptionFontEn}`} onClick={()=>{ setQuestionId( questionId == (key+1)? 0 : (key+1))} }> {questionId == (key+1)? "-" : "+"} </div>
                                    </div>
                                    <div className={styles.answerWrapper} >
                                        { (item.id == questionId) && <div className={`${styles.answer} ${i18n.language=="zh-HK"? styles.descriptionFontZhHK : styles.descriptionFontEn}`} > {item.answer} </div>}
                                        <hr className={styles.line}/>
                                    </div>  
                                </div>
                            </div>})}
                    </div>
                </div>
            </div>
        </div>
    </div> )
}

export default FaqPage